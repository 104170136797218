import React from "react";
import { Box, Paper, Typography, Grid, Stack } from "@mui/material";
import Mission from "../assets/mission.png";
import Vision from "../assets/vision.png";
import Values from "../assets/values.png";
import Nurture from "../assets/nuture.png";

function AboutUS() {
  const paperContent = [
    {
      imgSrc: `${Mission}`, // Replace with your image URL
      heading: "Our mission",
      text: "Revolutionize productivity with AI integration, fostering a balanced, efficient, and focused digital life.",
    },
    {
      imgSrc: `${Vision}`, // Replace with your image URL
      heading: "Our vision",
      text: "Make humans more productive and efficient in the world of AI dominance.",
    },
    {
      imgSrc: `${Values}`, // Replace with your image URL
      heading: "Our values",
      text: "Provide services without compromising individual's privacy and security, and to be transparent in our operations. ",
    },
  ];
  const valuesText = (
    <Stack alignItems={"center"}>
      <Stack
        padding={1}
        maxWidth={"1400px"}
        spacing={5}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center", // Center align text
        }}
      >
        <div style={{ height: "25px" }}></div>
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            textDecoration: "none",
            letterSpacing: "-2",
          }}
        >
          Navigate the Digital Sky with TimePilot!
        </Typography>
        <Typography
          maxWidth={"1000px"}
          variant="body1"
          sx={{
            color: "primary.main",
            textDecoration: "none",
            fontWeight: "200",
          }}
        >
          At TimePilot, we are committed to delivering tools that are not just
          technologically advanced but also human-centric. We stand by our
          promise to provide solutions that are intuitive, effective, and in
          tune with the needs of a dynamic workforce.{" "}
        </Typography>
      </Stack>
    </Stack>
  );
  const values = (
    <Stack width={"100%"} alignItems={"center"} direction={"column"}>
      <Stack
        padding={2}
        paddingY={6}
        direction={{ xs: "column", sm: "row" }}
        spacing={5}
        sx={{
          maxWidth: "1400px",
          justifyContent: "space-around",
          alignItems: "stretch",
        }}
      >
        {paperContent.map((content, index) => (
          <Paper
            key={index}
            square={false}
            elevation={3}
            sx={{
              width: 300, // Fixed width
              p: 2,
              backgroundColor: "#FBFAF5",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              mb: 2, // Margin bottom for spacing between rows
            }}
          >
            <Box
              sx={{
                height: "106px", // Fixed height for image
                mb: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={content.imgSrc}
                alt={`Paper ${index + 1}`}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
            </Box>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {content.heading}
            </Typography>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {content.text}
            </Typography>
          </Paper>
        ))}
      </Stack>
    </Stack>
  );
  const culture = <Stack justifyContent={"center"} alignItems={"center"}>
    <Box
      maxWidth={"1200px"}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap", // Wrap items for responsiveness
      }}
    >
      <img
        src={Nurture}
        alt="Team Culture"
        style={{ maxWidth: "100%", maxHeight: "100%", flex: 1 }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center", // Center align text
          m: 2, // Add margin for spacing
          flex: 1,
          paddingRight: { xs: "0px", sm: "0px", md: "10px" },
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 600,
            color: "primary.main",
            textDecoration: "none",
            mb: 2,
            letterSpacing: "-2",
          }}
        >
          Our Team Culture
        </Typography>
        <Typography
          variant="body1"
          sx={{
            color: "primary.main",
            textDecoration: "none",
            fontWeight: "200",
          }}
        >
          At TimePilot, our team culture is anchored in collaboration,
          innovation, and a deep passion for advancing productivity. We
          champion diversity and creativity, fostering an environment where
          every idea is valued and every voice is heard. Our commitment to
          open communication and mutual respect drives us to create
          AI-driven solutions that redefine efficiency and effectiveness in
          the digital age.
        </Typography>
      </Box>
    </Box>
  </Stack>;
  return (
    <>
      {valuesText}
      {values}
      {culture}
    </>
  );
}

export default AboutUS;
