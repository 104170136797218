import React from "react";
import { Typography, Box, useTheme } from "@mui/material";
import SubscribeInput from "./SubscribeInput";

const HeroText = () => {
  const theme = useTheme();
  const comingSoon = (
    <Box display="flex" alignItems="center" justifyContent={"flex-start"}>
      <hr
        style={{
          width: theme.spacing(3),
          height: "1.5px",
          backgroundColor: "#7e4e08",
          border: "none",
          alignSelf: "center",
          marginRight: theme.spacing(1),
          marginTop: theme.spacing(3),
        }}
      />
      <Typography
        variant="h5"
        noWrap
        sx={{
          mr: 2,
          display: "flex",
          fontWeight: 400,
          color: "primary.main",
          textDecoration: "none",
          letterSpacing: -1,
          fontSize: { xs: "1.25rem", sm: "1.5rem", md: "1.75rem" }, // Responsive font size
        }}
      >
        Coming Soon
      </Typography>
    </Box>
  );
  const getNotified = (
    <Box mt={1}>
      <Typography
        variant="h2"
        noWrap
        sx={{
          display: "flex",
          fontWeight: 600,
          color: "primary.main",
          textDecoration: "none",
          letterSpacing: -2,
          fontSize: { xs: "2.0rem", md: "2.5rem", lg:"3.0rem" }, // Responsive font size
        }}
      >
        Get Notified
      </Typography>
      <Typography
        variant="h2"
        noWrap
        sx={{
          display: "flex",
          fontWeight: 600,
          color: "primary.main",
          textDecoration: "none",
          letterSpacing: -2,
          fontSize: { xs: "2.0rem", md: "2.5rem", lg:"3.0rem" }, // Responsive font size
        }}
        mt={0}
      >
        When We Launch
      </Typography>
    </Box>
  );
  const notifyMe = (
    <Box mt={2} sx={{ width: { xs: "100%" } }}>
      {/* Responsive width */}
      <SubscribeInput />
      <Typography
        variant="subtitle1"
        noWrap
        sx={{
          mt: 2,
          display: "flex",
          color: "primary.main",
          textDecoration: "none",
        }}
      >
        *Don’t worry we will not spam you :)
      </Typography>
    </Box>
  );

  return (
    <Box backgroundColor={"transparent"} p={3} pt={2}>
      {comingSoon}
      {getNotified}
      {notifyMe}
    </Box>
  );
};

export default HeroText;
