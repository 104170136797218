import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {createTheme, CssBaseline} from "@mui/material";
import {ThemeProvider} from "@mui/material/styles";
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#7e4e08',
    },
    secondary: {
      main: '#EDDAB6',
    },
    inverted: {
      main: '#fcfaf0',
    },
    background: {
      default: '#FDFDFD', // Use 'default' instead of 'main' for background
    },
  },
  typography: {
    fontFamily: '"Noto Serif", serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        body {
          font-family: 'Noto Serif', serif;
    
        }
      `,
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline/>
    <App />
    </ThemeProvider>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
