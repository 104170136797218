import React from "react";
import { Box, Typography, Stack } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";

const ContactUs = () => {
  return (
    <Stack alignItems={"center"}>
      <Box
        maxWidth={"1400px"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          flexWrap: "wrap", // Wrap items for responsiveness
          paddingX: { xs: "8px", md: "32px" },
         
        }}
      >
        <Box
          sx={{
            paddingX: {xs:"8px",md:"32px"},
            display: "flex",
            flexDirection: "column",   
            textAlign: "start", // Center align text
            flex: 1,
           
          
          }}
          pb={5}
        >
          <Typography
            variant="h4"
            sx={{
              fontWeight: 600,
              color: "primary.main",
              textDecoration: "none",
              mb: 1,
              letterSpacing: "-2",
            }}
          >
            Contact Us
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: "primary.main",
              textDecoration: "none",
            }}
          >
            We're here to help! If you have any questions, need assistance, or
            want to share your feedback about TimePilot, please don't hesitate
            to reach out. Fill out the form, and our dedicated team will get
            back to you promptly. At TimePilot, your inquiries are important to
            us, and we're committed to providing you with the support and
            information you need.
          </Typography>
        </Box>
        <Box>
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSf252a_dr_nxioSWeJQDWWsBT7BTHXpmWxFrtY7ZnY0hQt5IQ/viewform?embedded=true"
            width={Math.min(window.innerWidth-32, 700)}
            height={"1100"}
          >
            Loading…
          </iframe>
        </Box>
        
      </Box>
    </Stack>
  );
};

export default ContactUs;
