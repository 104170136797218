import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import Nurture from "../assets/nuture.png";
import { Box, Typography } from "@mui/material";
import Mission from "../assets/mission.png";
import Vision from "../assets/vision.png";
import Values from "../assets/values.png";
import { Stack } from "@mui/system";

function FAQ() {
  const accordianStyle = {
    backgroundColor: "#FBFAF5",
    // border:'2',
  };
  const QnA = [
    {
      question: "What is TimePilot?",
      answer:
        "TimePilot is a leading AI-driven tool designed to revolutionize productivity. We offer innovative solutions to help individuals and organizations efficiently manage their time and resources in a digital world, ensuring a balanced and focused approach to work.",
    },
    {
      question: "How does TimePilot enhance productivity?",
      answer:
        "TimePilot enhances productivity by integrating advanced AI technologies to streamline work processes, reduce distractions, and provide insightful analytics. Our tools are tailored to optimize scheduling, focus, and efficiency, enabling users to maximize their potential in both personal and professional settings.",
    },
    {
      question: "Is TimePilot suitable for all types of businesses?",
      answer:
        "Absolutely! TimePilot is versatile and adaptable, making it suitable for businesses of all sizes and industries. Our solutions can be customized to meet specific organizational needs, ensuring that every team can benefit from enhanced efficiency and productivity.",
    },
    {
      question: "How does TimePilot ensure user data privacy and security?",
      answer:
        "User privacy and data security are paramount at TimePilot. We employ state-of-the-art encryption and security protocols to protect all user data. Our commitment to privacy is reflected in our strict adherence to global data protection regulations.",
    },

    {
      question: "What makes TimePilot different from other productivity tools?",
      answer:
        "TimePilot stands out with its unique AI-driven approach, focusing not just on task management but on enhancing overall cognitive productivity. Our solutions are designed to adapt to individual work styles, offering personalized insights and recommendations to help users work smarter, not harder.",
    },
    {
      question: "Can TimePilot be integrated with other tools and platforms?",
      answer:
        "Yes, TimePilot is built for integration. It can seamlessly connect with various popular tools and platforms to streamline your workflow. This integration capability ensures that TimePilot fits perfectly into your existing digital ecosystem.",
    },
    {
      question: "Do you offer support and training for TimePilot?",
      answer:
        "We provide comprehensive support and training resources for all our users. From detailed guides and tutorials to responsive customer support, we ensure that you have all the assistance you need to make the most of TimePilot.",
    },
    {
      question: "How does TimePilot help in managing remote teams?",
      answer:
        "TimePilot is an ideal tool for managing remote teams. It offers features like remote work analytics, communication facilitation, and project tracking, enabling managers to maintain productivity and cohesion among team members, regardless of their location.",
    },
    {
      question: "What are the system requirements for using TimePilot?",
      answer:
        "TimePilot is designed to be highly compatible and requires minimal system specifications. It works across various devices and operating systems, ensuring that you can access our tools whether you're on a PC, Mac, or mobile device. For specific system requirements, please refer to the technical details on our website.",
    },
  ];

  const FAQText = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "space-between",
        textAlign: "start", // Center align text
       
        paddingBottom: {xs:"8px" ,md: "16px" },
        flex: 1,
       
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          textDecoration: "none",
          mb: 1,
          letterSpacing: "-2",
        }}
      >
        Frequently <br /> Asked Questions
      </Typography>
      <Typography
        variant="body2"
        sx={{
          color: "primary.main",
          textDecoration: "none",
          fontWeight: "500",
        }}
      >
        Here's some information that may help with what your're looking for. If
        you need further assistance you can contact us directly.
      </Typography>
    </Box>
  );
  const FAQs = (
    <Box sx={{ width: "100%" }}>
      {QnA.map((content, index) => (
        <Accordion sx={{ my: 1 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            sx={accordianStyle}
          >
            {content.question}
          </AccordionSummary>
          <AccordionDetails sx={accordianStyle}>
            {content.answer}
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
  return (
    <Stack alignItems={"center"}>
      <Box
        sx={{
          maxWidth: "1300px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: {xs:"16px" ,md: "64px" },
          flexWrap: "wrap", // Wrap items for responsiveness
        }}
      >
        {FAQText}
        {FAQs}
      </Box>
    </Stack>
  );
}
export default FAQ;
