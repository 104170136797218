import React from "react";
import {
  Box,
  Container,
  IconButton,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import TwitterIcon from "@mui/icons-material/Twitter";
// Social Media Icons
const socialMediaIcons = [
  { icon: <FacebookIcon />, link: "https://www.facebook.com/profile.php?id=61555510784858" },
  { icon: <LinkedInIcon />, link: "https://www.linkedin.com/company/time-pilot/" },
  { icon: <MailOutlineIcon />, link: "mailto:contact@timepilot.tech" },
  { icon: <TwitterIcon />, link: "https://twitter.com/Timepilot2024" },
 
];

// In-Page Links
const inPageLinks = ["Home", "About Us", "Join Us", "FAQ", "Contact Us"];

// Footer Component
function Footer({onNavigate}) {
  return (
    <Stack
      sx={{
        backgroundColor: "black",
        borderRadius: "20px 20px 0px 0px",
        flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        mt: { lg: 20, md: 10 },
        ml: 10,
      }}
    >
      <Stack maxWidth="sm">
        <Stack justifyContent={"space-around"} direction={"row"} padding={2}>
          {/* Social Media Icons */}
          {socialMediaIcons.map((item, index) => (
            <IconButton
              key={index}
              href={item.link}
              target="_blank"
              sx={{
                borderRadius: "50%",
                backgroundColor: "white", // White background
                color: "black", // Black icon color
                "&:hover": {
                  backgroundColor: "white", // Black background on hover
                  color: "primary.main", // White icon color on hover
                  transition: "0.5s", // Smooth transition between hover and non-hover states
                },
                margin: 1,
                padding: 1,
              }}
            >
              {React.cloneElement(item.icon, { sx: { fontSize: "inherit" } })}
            </IconButton>
          ))}
        </Stack>

        <Stack direction={"row"} justifyContent={"center"} pl={2}>
          {/* In-Page Links */}
          {inPageLinks.map((link, index) => (
            <Typography
              key={index}
              noWrap
              component="a"
              variant="body1"
              textDecoration="none"
              onClick={() => onNavigate(link)}
              sx={{
                color: "white",
                fontSize:{xs:"0.7rem", md:"0.8rem", lg:"0.9rem"},
                fontWeight: 100,
                marginRight: "20px",
                marginLeft: "5px",
                textDecoration: "none",
                position: "relative", // Required for absolute positioning of the pseudo-element
                "&::before": {
                  // Using ::before pseudo-element for custom underline
                  content: '""', // Empty content
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  height: "2px",
                  backgroundColor: "primary.main", // Use theme primary color
                  width: "0%",
                  transition: "width 0.3s ease-in-out",
                },
                "&:hover::before": {
                  // Only expand underline on hover for the specific link
                  width: "100%",
                },
              }}
            >
              {link}
            </Typography>
          ))}
        </Stack>
      </Stack>

      {/* Copyright Notice */}
      <Typography variant="body2" color="grey" sx={{ mt: 4, mb: 5, fontSize:{xs:"0.7rem", md:"0.8rem", lg:"0.9rem"} }} noWrap >
        2024 | Timepilot Software Pvt ltd | All Rights Reserved
      </Typography>
    </Stack>
  );
}

export default Footer;
