import React from "react";
import { Box, Paper, Typography, Grid, Stack, IconButton} from "@mui/material";
import FrontEnd from "../assets/frontEnd.png";
import BackEnd from "../assets/backEnd.png";
import Nlp from "../assets/NLP.png";
import Mission from "../assets/mission.png";
import Vision from "../assets/vision.png";
import Values from "../assets/values.png";
import Nurture from "../assets/nuture.png";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

function JoinUs() {
  const paperContent = [
    {
      imgSrc: `${Nlp}`, // Replace with your image URL
      heading: "NLP Engineer",
      applyLink: "https://unstop.com/internships/nlp-engineering-internship-time-pilot-software-private-limited-878393",
    },
    {
      imgSrc: `${FrontEnd}`, // Replace with your image URL
      heading: "Frontend Engineer",
      applyLink: "https://unstop.com/internships/software-developer-frontend-internship-time-pilot-software-private-limited-878444",
    },
    {
      imgSrc: `${BackEnd}`, // Replace with your image URL
      heading: "Backend Engineer",
      applyLink: "https://unstop.com/internships/software-developer-backend-internship-time-pilot-software-private-limited-878466",
    },
  ];

  const headerText = (
    <Box
      sx={{
        paddingX: { xs: "16px", md: "64px" },
        // paddingX: { xs: "8px", md: "32px" },
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        textAlign: "start",
        alignItems: "start",
        flex: 1,
        mb: 4,
      }}
     
      maxWidth={"1400px"}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          textDecoration: "none",
          mb: 2,
          letterSpacing: "-2",
        }}
      >
        Join Us at TimePilot – Where Visionaries and Technology Unite!
      </Typography>
      <div style={{ height: "30px" }}></div>
      {/* Join us content */}
      <Typography
        variant="body1"
        sx={{
          color: "primary.main",
          textDecoration: "none",
          fontWeight: "200",
        }}
      >
        Embark on a journey where your skills and creativity shape the future of
        AI. At TimePilot, we blend visionary thinking with cutting-edge
        technology, creating a space where innovation thrives. Be part of a team
        that's redefining the digital landscape, one breakthrough at a time.
        Your path to making a significant impact in the world of AI starts here.
      </Typography>
    </Box>
  );
  const currentOpeningsText = (
    <Box
      sx={{
        paddingX: { xs: "8px", md: "32px" },
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "start",
        textAlign: "start",
        flex: 1,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          fontWeight: 600,
          color: "primary.main",
          textDecoration: "none",
          mb: 4,
          letterSpacing: "-2",
        }}
      >
        Explore Our Current Openings
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "primary.main",
          textDecoration: "none",
          fontWeight: "200",
        }}
      >
        Curious about the roles we're currently seeking to fill? Check out our
        listings bellow to find your perfect fit.
      </Typography>
      <Typography
        variant="subtitle2"
        align="start"
        sx={{
          fontWeight: 200,
          color: "primary.main",
          textDecoration: "none",
          letterSpacing: "-2",
          marginTop: "40px",
        }}
      >
        Can't Find What You're Looking For?
      </Typography>
      <Typography
        variant="body2"
        align="start"
        sx={{
          color: "primary.main",
          textDecoration: "none",
          fontWeight: "100",
        }}
      >
        Send us your resume at <b>contact@timepilot.tech.</b> Let us know why
        you're passionate about working with us, and how you envision
        contributing to our mission.
      </Typography>
    </Box>
  );
  const currentOpenings = (
    <Stack
      spacing={2}
      sx={{
        width: "100%",
        paddingX: { xs: "16px" },
        display: "flex",
        flexDirection: { xs: "column" },
        justifyContent: "center",
        alignItems: "center",
        flexWrap: "wrap", // Wrap items for responsiveness
        mt: 7,
      }}
    >
      {paperContent.map((content, index) => (
        <Paper
          key={index}
          elevation={3}
          sx={{
            width: { xs: "85vw", md: "350px", lg: "500px" }, // Fixed width
            p: 1,
            backgroundColor: "#FBFAF5",
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            mr: 5,
            borderRadius: "30px",
          }}
        >
          <Box
            sx={{
              height: "70px", // Fixed height for image

              // mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "20",
            }}
          >
            <img
              src={content.imgSrc}
              alt={`Paper ${index + 1}`}
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </Box>
          <Box
            sx={{
              mb: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ml: 2,
            }}
          >
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              {content.heading}
            </Typography>
          </Box>
          {/*<Typography variant="body2" sx={{ flexGrow: 1 }}>{content.text}</Typography>*/}
          <Box sx={{ ml: 'auto' }}>
            <IconButton
              href={content.applyLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <OpenInNewIcon />
            </IconButton>
          </Box>
        </Paper>
      ))}
    </Stack>
  );
  return (
    <Stack
      justifyContent={"center"}
      alignItems={"center"}
     
      
    >
     
        {headerText}

        <div style={{ height: "50px" }}></div>

        <Stack
          maxWidth={"1400px"}
          justifyContent={"space-around"}
          alignItems={"center"}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            paddingX: { xs: "8px", md: "32px" },
          }}
        
        >
          <Stack direction={"column"}>{currentOpeningsText}</Stack>
          {currentOpenings}
        </Stack>
   
    </Stack>
  );
}

export default JoinUs;
