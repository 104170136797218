import React, { useEffect, useState } from "react";
import Logo from "../assets/LogoClock.png";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

// Constants for pages and mobile breakpoint
const pages = ["Home", "About Us", "Join Us", "FAQ", "Contact Us"];
const MOBILE_BREAKPOINT = 500;

// Desktop Menu Component
const DesktopMenu = ({ pages, onClose, onNavigate }) => (
  <Box
    sx={{
      flexGrow: 1,
      display: { xs: "none", sm: "none", md: "flex" },
      justifyContent: "flex-start",
    }}
    ml={4}
  >
    {pages.map((page) => (
      <Button
        key={page}
        onClick={() => onNavigate(page)}
        sx={{
          display: "block",
          fontWeight: "500",
          fontSize: "17px",
          color: "primary.main",
          textTransform: "none",
          textDecoration: "none", // Ensure no default underline
          borderRadius: "20px",
          marginLeft: "12px",
          overflow: "hidden",
          "&::before": {
            // Using ::before pseudo-element for custom underline
            content: '""', // Empty content
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "2px",
            backgroundColor: "primary.main",
            width: "0%",
            transition: "width 0.3s ease-in-out",
          },
          "&:hover::before": {
            // Only expand underline on hover
            width: "100%",
          },
        }}
      >
        {page}
      </Button>
    ))}
  </Box>
);

// Mobile Menu Component
const MobileMenu = ({ anchorEl, open, onClose, pages, onNavigate }) => (
  <Menu
    id="menu-appbar"
    anchorEl={anchorEl}
    open={open}
    onClose={() => onClose(null)}
    sx={{
      display: { sm: "block", md: "block", background: "background.main" },
    }}
  >
    {pages.map((page) => (
      <MenuItem key={page} onClick={() => {onNavigate(page); onClose(page);}}>
        <Typography textAlign="center">{page}</Typography>
      </MenuItem>
    ))}
  </Menu>
);

// Main Navbar Component
function Navbar({onNavigate}) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = (page) => setAnchorElNav(null);

  const logoStyle = {
    display: windowWidth < MOBILE_BREAKPOINT ? "flex" : "flex",
    width: "35px",
    height: "35px",
    marginRight: "15px",
  };


  return (
    <Container maxWidth="2500px"  style={{ position: "relative" }}>
      <AppBar
        position="absolute"
        sx={{ backgroundColor: {xs:"transparent"}, boxShadow: "none", pt: 2}}
      >
        <Toolbar>
          <IconButton
            size="small"
            onClick={handleOpenNavMenu}
            sx={{ mr: 1, display: { xs: "flex", sm: "flex", md: "none" } }}
            color="primary.main"
          >
            <MenuIcon />
          </IconButton>

          <img src={Logo} alt="Logo" style={logoStyle} />

          <Typography
            variant="h4"
            component="a"
            href="#home"
            sx={{
              display: "flex",
              fontWeight: 600,
              color: "primary.main",
              fontSize: "25px",
              textDecoration: "none",
            }}
          >
            Time Pilot
          </Typography>

          <MobileMenu
            anchorEl={anchorElNav}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            pages={pages}
            onNavigate={onNavigate}
          />

          <DesktopMenu  pages={pages} onClose={handleCloseNavMenu} onNavigate={onNavigate} />
        </Toolbar>
      </AppBar>
    </Container>
  );
}

export default Navbar;
