import React from "react";
import HeroSection from "../Sections/HeroSection";
import NavigationBar from "../Sections/NavigationBar";
import { Stack, Box, useTheme } from "@mui/material";
import AboutUS from "../Sections/AboutUs";
import JoinUs from "../Sections/JoinUs";
import FAQ from "../Sections/FAQ";
import ContactUs from "../Sections/SurveyMonkey";
import Footer from "../Sections/Footer";

function ComingSoonPage() {
  const theme = useTheme();
  
  const scrollTo = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Box width={"100%"} sx={{ maxWidth: "2500px" }}>
      <Stack direction={"column"} width={"100%"} sx={{ maxWidth: "2500px" }}>
        <Box
          width={"100%"}
          height={"80px"}
          sx={{ maxWidth: "2500px", height: { xs: "80px", md: "100%" } }}
        >
          <NavigationBar onNavigate={scrollTo} />
        </Box>
        <Box id="Home" mb={3} sx={{ height: { xs: "calc(100vh - 80px)", md: "100vh" } }}>
          <HeroSection />
        </Box>
        <Stack
        maxWidth={"2500px"}
          // alignItems={"center"}
          spacing={5}
          sx={{ paddingX: { xs: "8px", sm: "16px", md: "16px" } }}
        >
          <Box id="About Us" borderRadius={5} sx={{backgroundColor:'#fcfaf0'}} pb={5}>
            <AboutUS />
          </Box>
          <Box id="Join Us" display={"flex"} justifyContent={"center"} borderRadius={5}  backgroundColor={'#fcfaf0'} py={7}>
          <JoinUs />
        
          </Box>
          <Box id="FAQ" borderRadius={5} sx={{backgroundColor:'#fcfaf0'}} py={5}>
          <FAQ />
          </Box>
          <Box id="Contact Us" borderRadius={5} sx={{backgroundColor:'#fcfaf0'}} py={5}>
          <ContactUs />
          </Box>
          <Footer onNavigate={scrollTo} />
        </Stack>
      </Stack>
    </Box>
  );
}
export default ComingSoonPage;
