import React, {useState} from "react";
import { Box, TextField, Button, useTheme, Stack } from "@mui/material";

function SubscribeInput() {
  const theme = useTheme();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("Notify Me");

  function sendDataToGoogleSheet(email) {
    // Construct the URL with the email query parameter
    const url = `https://script.google.com/macros/s/AKfycbzUlFg9xOoG0H-jq_9oM6QZ0vq2_UKSGBylqKU_9Tw8itLZcc4YNP1ejO83QI5w7T53/exec?email=${encodeURIComponent(email)}`;

    fetch(url, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.error("Error:", error));
  }

  const handleNotifyMe = () => {
    if (email !== "") {
      sendDataToGoogleSheet(email);
      setMessage("Thank you!");
      setEmail("");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        border: "2px solid",
        borderColor: "#7e4e08",
        borderRadius: "50px",
        flexDirection: "row", // Stack elements vertically on small screens
        gap: theme.spacing(2), // Add space between elements
      }}
    >
      {/* sss */}
      <TextField
        variant="filled"
        placeholder="Enter your email address"
        value={email}
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        sx={{
          ".MuiFilledInput-root": {
            background: "transparent",
            borderRadius: "50px",
            "&:hover": {
              background: "transparent",
            },
          },
          width: "60%", // Full width on all screen sizes
          ".MuiInputBase-input": {
            padding: theme.spacing(1),
            "::placeholder": {
              color: "primary.main",
              fontSize: "0.8rem",
              paddingLeft: "10px",
            },
          },
          flex: 1, // TextField takes up remaining space
        }}
        InputProps={{
          disableUnderline: true,
        }}
      />
      <Button
        variant="contained"
        onClick={handleNotifyMe}
        sx={{
          background: "#7e4e08",
          "&:hover": {
            background: "#7e4e08",
          },
          borderRadius: "50px",
          width: "40%", // Full width on small screens, fixed width on larger screens
          height: "61px",
          whiteSpace: "nowrap",
        }}
      >
        {message}
      </Button>
    </Box>
  );
}

export default SubscribeInput;
