import React from 'react';
import Rocket from '../assets/rocket.png';
import Clock from '../assets/Clock2.png';
import { Box, useTheme, useMediaQuery } from '@mui/material';


const DotAnimation = ({ scale, x, y, rocketImg = Rocket, centerImg = Clock }) => {
  const theme = useTheme();
  const rocketSizeValue = scale; 
  const isMediumOrLarger = useMediaQuery(theme.breakpoints.up('md')); // Checks if the screen size is 'md' or larger
  const unit = isMediumOrLarger ? 'vh' : 'vw';
  const rocketSize = `${rocketSizeValue}${unit}`;
  const clockSize = `${3.8 * rocketSizeValue}${unit}`;

  const loaderStyle = {
    position: 'relative',
    top: `${y}vh`, 
    left: `${x}vw`, 
    transform: 'translate(-50%, -50%)',
  };

  const commonStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%', 
    transformOrigin: 'center', 
  };

  const planeStyle = {
    ...commonStyle,
    width: clockSize,
    height: clockSize,
    animation: 'rotate 5s linear infinite',
  };

  const secondRocketStyle = {
    ...commonStyle,
    width: clockSize,
    height: clockSize,
    animation: 'rotateDelayed 5s linear infinite',
    animationDelay: '2.5s',
  };

  const imgStyle = {
    width: rocketSize,
    transform: 'rotate(-45deg)',
  };

  const centerImageStyle = {
    ...commonStyle,
    width: clockSize,
    height: clockSize,
  };

  return (
    <Box position="relative">
      <style>
        {`@keyframes rotate {
          0% { transform: rotate(0deg); }
          15% { transform: rotate(-45deg); }
          25% { transform: rotate(-90deg); }
          35% { transform: rotate(-135deg); }
          50% { transform: rotate(-180deg); }
          65% { transform: rotate(-225deg); }
          75% { transform: rotate(-270deg); }
          100% { transform: rotate(-360deg); }
        }`}
        {`@keyframes rotateDelayed {
          0% { transform: rotate(0deg); }
          15% { transform: rotate(-45deg); }
          25% { transform: rotate(-90deg); }
          35% { transform: rotate(-135deg); }
          50% { transform: rotate(-180deg); }
          65% { transform: rotate(-225deg); }
          75% { transform: rotate(-270deg); }
          100% { transform: rotate(-360deg); }
        }`}
      </style>
      <Box style={loaderStyle}>
        <Box style={planeStyle}>
          <img src={rocketImg} alt="Rocket" style={imgStyle} />
        </Box>
        <Box style={secondRocketStyle}>
          <img src={rocketImg} alt="Second Rocket" style={imgStyle} />
        </Box>
        <img src={centerImg} alt="Center Image" style={centerImageStyle} />
      </Box>
    </Box>
  );
}

export default DotAnimation;
