import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ComingSoonPage from "./Pages/ComingSoonPage";
import { Container } from "@mui/material";

function App() {
  return (
    <Container 
      maxWidth={false} // Disable the default maxWidth
      disableGutters
      sx={{
        width: '100%', 
        maxWidth: '2500px', // Set your custom maxWidth
        height: '100%',
        margin: 'auto', // Add this to center the Container
        overflowY: 'hidden',
        // backgroundColor: 'grey',
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<ComingSoonPage />} />
        </Routes>
      </Router>
    </Container>
  );
}

export default App;
