import React, { useEffect, useState } from "react";
import { Box, Grid, useTheme, Stack, Typography } from "@mui/material";
import EmailNotification from "../Components/EmailNotification";
import DotAnimation from "../Components/Dot Animation";
import HeroText from "../Components/HeroText";

function HeroSection2() {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getDotAnimationProps = () => {
    if (screenSize < 600) {
      // Mobile
      return { scale: 40, x: -25, y: -17 };
    } else if (screenSize < 900) {
      // Tablet
      return { scale: 40, x: -25, y: -17 };
    } else if (screenSize < 1200) {
      // Tablet
      return { scale: 30, x: 11, y: -55 };
    } 
    else if (screenSize < 2000) {
      // Desktop and larger
      return { scale: 30, x: 25, y: -55 };
    }
    else {
      // Desktop and larger
      return { scale: 30, x: 11, y: -55 };
    }
  };

  const dotAnimationProps = getDotAnimationProps();

  return (
    <Stack
      sx={{
        width: "100vw",
        direction: "column",
        justifyContent: "center",
        overflow: "hidden",
        height: {xs:"calc(100vh - 81px)", md:"100vh"},
      }}
    >
      <Box sx={{ display: "flex", overflowX: "hidden", height: "100vh" }}>
        <Stack
          justifyContent={"flex-start"}
          sx={{
            flexDirection: { xs: "column", md: "row" },
            alignItems: { xs: "center" },
            overflow: "hidden",
          }}
        >
          <Box flexGrow={0.5} sx={{width: { xs: "100vw", md: "50vw" }, maxWidth: "1234px", paddingLeft:{md:"24px"} }}>
            <HeroText />
          </Box>
          <Box flexGrow={0.5} sx={{ width: { xs: "100vw", md: Math.min("50vw", "1234px") }, maxWidth: "1250px" }}>
            <DotAnimation {...dotAnimationProps} />
          </Box>
        </Stack>
      </Box>
    </Stack>
  );
}
export default HeroSection2;
